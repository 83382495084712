@import "../../variables";
.features-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 50px 0;
  background-image: url(../../images/bg2.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  > .features-container {
    max-width: $appmaxwidth;
    width: calc(100% - 200px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 100px;
    >.title{
      margin-bottom: 20px;
      h2 {
        font-weight: 700;
        font-size: 48px;
        margin-bottom: 0;
        color: #FFFFFF;
      }
    }
    
    > .content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    .card {
     
      color: #ffffffda;
      padding: 1.5rem;
      background-color: #202022;
      
      pointer-events: auto;
      
      transform: scale(1);
      opacity: 1;
      // transition: all 150ms ease-in-out; 
      
      display: flex;
      flex-direction: column;
      
      .card-title {
        position: relative;
        
        &::before {
          content: "";
          height: 1rem;
          width: 1rem;
          border-radius: 50%;
          box-shadow: inset 0 0 3px #bb86fc;
          background-color: transparent;
          
          position: absolute;
          right: 0;
        }
      }
      
      
      // &:nth-child(even) {
      //   transform: translateY(8px);
      // }
      
      // &:nth-child(n) {
      //   transform: rotate(-5deg);
      // }
    }
  
  
  .cards:hover > .card:hover {
    transform: scale(1.15);
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    z-index: 10;
       &:nth-child(even) {
        transform: translateY(8px);
      }
      
      &:nth-child(n) {
        transform: rotate(-5deg);
      }
  }
  .cards:hover > .card:not(:hover) {
    opacity: 0.5;
  }
  }
}
@media screen and (max-width: 800px) {
  .features-wrapper {
    > .features-container {
     padding: 0 20px;
     width: calc(100% - 40px);
     >.title{
      h2 {
        font-size: 30px;
      }
    }
    }
  }
}