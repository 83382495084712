@import "../../variables";
.footer-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin: 3rem 0;
  z-index: 100;
  .footer-content {
    max-width: $appmaxwidth;
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
        flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
   >div{
    display: flex;
    flex-direction: column;
    p{
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #676B7C;
    }
    h1{
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;
    }>div{
      a{
        font-weight: 600;
font-size: 16px;
line-height: 24px;
text-decoration: none;
color: #676B7C;
      }
    }
   }
  }
}
@media screen and (max-width: 768px) {
  .footer-wrapper {
    .footer-content {
             display: flex;
      flex-direction: column;
      align-items: flex-start;
     >div{

     }
    }
  }
}
