@import "../../variables";
.plans-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 50px 0;
  background-image: url(../../images/bg4.png);
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  .plans-container {
    max-width: $appmaxwidth;
    width: calc(100% - 200px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 100px;
    >.title{
      margin-bottom: 20px;
      h2 {
        font-weight: 700;
        font-size: 48px;
        margin-bottom: 0;
        color: #FFFFFF;
      }
    }
    .tabs{
      display: flex;
      justify-content: center;
      align-items: center;
      h4{
        font-weight: 400;
        font-size: 18px;
        color: #FFFFFF;
        // margin: 0 20px;
      }
      .tabs-container{
        background: #8649EF;
        border-radius: 85px;
        width: 116px;
        height: 63px;
        margin: 0 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        button{  
          background-color: transparent;        
          border-radius: 50%;
          border: none;
          outline: 0;
          width: 55px;
          height: 55px;}
        .selected{
          background: #FFFFFF;

        }
      }
    }
    > .content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

    }
  }
}
@media screen and (max-width: 800px) {
  .plans-wrapper {
    > .plans-container {
     padding: 0 20px;
     width: calc(100% - 40px);
     >.title{
      h2 {
        font-size: 30px;
      }
    }
    }
  }
}