.featuresCard-wrapper {
  background: rgba(24, 24, 24, 0.8);
  border: 1.5px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  color: #fff;
  width: 260px;
  height: 420px;
  margin: 40px;
  padding: 20px;
  > img {
    width: 260px;
  }
  h5{
    font-weight: 600;
    font-size: 22px;
    color: #fff;
  }
  > p {
    font-weight: 400;
    font-size: 14px;
    /* or 178% */
    line-height: 24px;
    
    color: #FFFFFF;
  }
}
