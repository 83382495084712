@import "../../variables";
.mobileNavbar-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 500;
  padding-top: 20px;
 
  > .mobileNavbar-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    > .top {
      display: flex;
      justify-content: space-between;
      width: calc(100% - 40px);
      padding: 0 20px;
      
      > a {
        text-decoration: none;
        > div {
          position: fixed;
          display: flex;
          align-items: center;
          justify-content: center;
          > h6 {
             font-size: 18px;
              font-weight: 400;
              letter-spacing: 5px;
              color: #f03535;
              margin: 0;
              margin-top: 10px;
          }
          > img {
            width: 45px;
            height: 45px;
            
           
            padding: 0;
            
          }
        }
      }
      > img {
        width: 40px;
        margin-top: -20px;
      }
      > a {
        height: 60px;
        > img {
          width: 150px;
        }
      }
    }

    > .bottom {
      background-color: #1E1E1E;
      display: flex;
      flex-direction: column;
      text-align: center;
      height: 80%;
      width: 100%;
      > a {
        color: #fff;
        font-size: 14px;
        text-decoration: none;
        padding: 10px 20px;
      }
      a{
        text-decoration: none;
      }
      button {
        position:relative;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 1px;
        color: #fff;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 146px;
        height: 45px;
        background: #8649EF;
        border-radius: 8px;
        border: none;
        outline: 0;
        overflow:hidden;
        margin-left: 20px;
        margin-bottom: 10px;
      }
      .sheen:after{
        content: '';
        position: absolute;
        top: -50%;
        right: -50%;
        bottom: -50%;
        left: -50%;
        background: linear-gradient(to bottom, rgba(229, 172, 142, 0), rgba(255,255,255,0.5) 50%, rgba(229, 172, 142, 0));
        transform: rotateZ(60deg) translate(-5em, 7.5em);
      }
      /*This activates the sheen once the button has been hovered*/
      .sheen:hover::after, button:focus::after {
        animation: sheen 1s forwards;
      }
      @keyframes sheen {
        100% {
          transform: rotateZ(60deg) translate(1em, -9em);
        }
      }
    }
  }
}
.mobileNavbar-wrapper.active {
  position: fixed;
  background-color: #1E1E1E;
  padding: 20px 0;
 
}
.mobileNavbar-wrapper.nav{
  .mobileNavbar-container {
    > .top {
      > a {
        > div {
          img{
            display: none!important;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1161px) {
  .mobileNavbar-wrapper {
    display: none;
  }
}
