@import "../../variables";
.faq-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  color: #fff;
  background-image: url(../../images/bg5.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  // top: -350px;
  padding: 100px 0;
  .faq-container {
    max-width: $appmaxwidth;
    width: calc(100% - 200px);
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 100px;
    >.title{
        width: 100%;
        margin-bottom: 20px;
        h2 {
          font-weight: 700;
          font-size: 48px;
          margin-bottom: 0;
          color: #FFFFFF;
        }
      }
    .faqcard-wrapper {
      cursor: pointer;
      color: #fff;
      background: rgba(23, 23, 23, 0.9);
      border: 1.5px solid rgba(255, 255, 255, 0.1);
      border-radius: 10px;
      box-sizing: border-box;
      padding: 15px;
      display: flex;
      justify-content: center;
      text-align: left;
      margin: 20px 0;
      width: 100%;
       .selector-container {
        width: 10%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: end;
        color: #8ccffb;

        > img {
          width: 30px;
          margin-top: 8px;
          margin-right: 2px;
        }
        .clicked {
          transform: rotate(180deg);
        }
      }
      
    }
  }
}
@media screen and (max-width: 800px) {
  .faq-wrapper {
    > .faq-container {
     padding: 0 20px;
     width: calc(100% - 40px);
     >.title{
      h2 {
        font-size: 30px;
      }
    }
    }
  }
}
h1 {
  text-align: center;
}

.accordion {
 width: 100%;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}
.faqs {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  > h6 {
    font-size: 18px;
    margin: 0;
    font-weight: 600;
  }
  > p {
    font-size: 16px;
    margin: 0;
    
  }
}
.accordion-title:hover {

}

.accordion-title,
.accordion-content {
}

.accordion-content {

}