@import "../../variables";
.home-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  > .home-container {
    max-width: $appmaxwidth;
    width: calc(100% - 200px);
    height: calc(100vh - 150px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    margin-top: 150px;
    padding: 0 100px;
   .left{
      width: 50%;
      text-align: left;
      h1{
        font-weight: 700;
        font-size: 58px;
        line-height: 78px;
        color: #fff;
        text-align: left;
        margin-top: 0;
     >span{
      mix-blend-mode: screen;
      opacity: 0.8;
     }
      }
      .button{
        width: 146px;
        > a {
         
          text-decoration: none;
          cursor: pointer;
        }
      }
      button {
        position:relative;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 1px;
        color: #fff;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 146px;
        height: 45px;
        background: #8649EF;
        border-radius: 8px;
        border: none;
        outline: 0;
        overflow:hidden;
         cursor: pointer;
      transition: all 500ms linear;
      transition-delay: 0.1s;
      }
       button:hover {
      color: #8649EF;
      background-color: #fff;
    }
      // .sheen:after{
      //   content: '';
      //   position: absolute;
      //   top: -50%;
      //   right: -50%;
      //   bottom: -50%;
      //   left: -50%;
      //   background: linear-gradient(to bottom, rgba(229, 172, 142, 0), rgba(255,255,255,0.5) 50%, rgba(229, 172, 142, 0));
      //   transform: rotateZ(60deg) translate(-5em, 7.5em);
      // }
      // /*This activates the sheen once the button has been hovered*/
      // .sheen:hover::after, button:focus::after {
      //   animation: sheen 1s forwards;
      // }
      // @keyframes sheen {
      //   100% {
      //     transform: rotateZ(60deg) translate(1em, -9em);
      //   }
      // }
   }
   .right{
    width: 50%;
    text-align: right;
    img{
      width: 450px;
    }
   }
  }
}
@media screen and (max-width: 1100px) {
  .home-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    > .home-container {
     display: flex;
     flex-direction: column;
     .left{
      width: 100%;
      text-align: center !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 100px 0;
      h1{
        font-weight: 700;
        font-size: 40px;
        color: #fff;
        line-height: 50px;
        text-align: center;
      }
      a{
        button{
          background: #8649EF;
          border-radius: 8px;
          width: 146px;
          height: 45px;
          border: none;
          outline: 0;
          font-weight: 600;
          font-size: 14px;
          color: #fff;
        }
       
      }
   }
   .right{
    width: 100%;
    margin-bottom: 100px;
    text-align: center;
    img{
      width: 300px;
    }
   }
    }
  }
}
@media screen and (max-width: 800px) {
  .home-wrapper {
    > .home-container {
     padding: 0 20px;
     width: calc(100% - 40px);
    
    }
  }
}