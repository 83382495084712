@import "../../variables";
.partnerships-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 50px 0;
  background-image: url(../../images/bg3.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  > .partnerships-container {
    max-width: $appmaxwidth;
    width: calc(100% - 200px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 100px;
    >.title{
      margin-bottom: 20px;
      h2 {
        font-weight: 700;
        font-size: 48px;
        margin-bottom: 0;
        color: #FFFFFF;
      }
    }
    
    > .content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
@media screen and (max-width: 800px) {
  .partnerships-wrapper {
    > .partnerships-container {
     padding: 0 20px;
     width: calc(100% - 40px);
     >.title{
      h2 {
        font-size: 30px;
      }
    }
    }
  }
}