.planCard-wrapper {
  width: 300px;
  height: 430px;
  margin: 20px;
  padding: 20px;
  background: #171717;
  border: 1.5px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
 .title{
  background: #8649EF;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  width: 100%;
  
  h3{
    font-size: 30px;
    color: #fff;
    padding: 0 20px ;
  }
 }

    h1{
      font-weight: 700;
      font-size: 36px;
      color: #FFFFFF;
      margin: 0;
    }
    h6{
      font-weight: 400;
      font-size: 13px;
      margin-bottom: 6px;
      margin-top: 0;
      color: #FFFFFF;
      margin-left: 5px;
    }

    .middle{
      height: 240px;
      ul{
        margin: 0!important;
        padding: 0 10px ;
      }
      img{
        height: 13px;
        margin-right: 5px;
        margin-top: 3px;
      }
      li{
        list-style: none;
        font-weight: 400;
        font-size: 13px;
        line-height: 134%;
        margin-bottom: 20px;
        color: #FFFFFF;
        display: flex;
      }
    }
   
  
     
      button {
        position:relative;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 1px;
        color: #fff;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 146px;
        height: 45px;
        background: #8649EF;
        border-radius: 8px;
        border: none;
        outline: 0;
        overflow:hidden;
        display: flex;
        justify-content: center;
        transition: all 500ms linear;
        transition-delay: 0.1s;
      
      }
      button:hover {
        color: #8649EF;
        background-color: #fff;
      }
      a {
        text-decoration: none !important;
        text-align: center;
        display: flex;
        justify-content: center;
        width: 146px;
        > button {
          cursor: pointer;
          border: none;
          background: #8649EF;
          border-radius: 5px;
          width: 160px;
          height: 40px;
          display: block;
          text-align: center;
          font-size: 13px;
          font-weight: bold;
          margin-top: 10px;
          color: #fff;
          transition: all 500ms linear;
          transition-delay: 0.1s;
        }
      }
      // .sheen:after{
      //   content: '';
      //   position: absolute;
      //   top: -50%;
      //   right: -50%;
      //   bottom: -50%;
      //   left: -50%;
      //   background: linear-gradient(to bottom, rgba(229, 172, 142, 0), rgba(255,255,255,0.5) 50%, rgba(229, 172, 142, 0));
      //   transform: rotateZ(60deg) translate(-5em, 7.5em);
      // }
      // /*This activates the sheen once the button has been hovered*/
      // .sheen:hover::after, button:focus::after {
      //   animation: sheen 1s forwards;
      // }
      // @keyframes sheen {
      //   100% {
      //     transform: rotateZ(60deg) translate(1em, -9em);
      //   }
      // }
}
@media screen and (max-width: 480px) {
  .planCard-wrapper {
    width: 100%;
    height: max-content;
    >div{
      .middle{
        height: max-content;

      }
    }
  }
}