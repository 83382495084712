@import "../../variables";
.navbar-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 500;
  height: 150px;
  color: #fff;
  > .navbar-container {
    width: 100%;
    max-width: $appmaxwidth;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 0 100px;
    > a {
      text-decoration: none;
      >div{
        display: flex;
        justify-content: center;
        align-items: center;
        >h6{
          font-size: 18px;
          font-weight: 400;
          letter-spacing: 5px;
          color: #f03535;
        }
      }
       img{
        width: 70px;
       
      }
    }
   >div{ 
    display: flex;
    > .links {
      display: flex;
      align-items: center;
      > a {
        color: #FFFFFF;
        font-weight: 600;
        font-size: 16px;
        padding: 0 25px;
        text-decoration: none;
        margin-top: 11px;
        transition: all 500ms linear;
        transition-delay: 0.1s;
      }
      > a:hover {
        color: #8649EF;
      }
      
    }}
    .button{
      > a {
       
        text-decoration: none;
        cursor: pointer;
      }
    }
    button {
      position:relative;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 1px;
      color: #fff;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 146px;
      height: 45px;
      margin-left: 25px;
      background: #8649EF;
      border-radius: 8px;
      border: none;
      outline: 0;
      overflow:hidden;
      cursor: pointer;
      transition: all 500ms linear;
      transition-delay: 0.1s;
    }
    button:hover {
      color: #8649EF;
      background-color: #fff;
    }
    // .sheen:after{
    //   content: '';
    //   position: absolute;
    //   top: -50%;
    //   right: -50%;
    //   bottom: -50%;
    //   left: -50%;
    //   background: linear-gradient(to bottom, rgba(229, 172, 142, 0), rgba(255,255,255,0.5) 50%, rgba(229, 172, 142, 0));
    //   transform: rotateZ(60deg) translate(-5em, 7.5em);
    // }
    // /*This activates the sheen once the button has been hovered*/
    // .sheen:hover::after, button:focus::after {
    //   animation: sheen 1s forwards;
    // }
    // @keyframes sheen {
    //   100% {
    //     transform: rotateZ(60deg) translate(1em, -9em);
    //   }
    // }
  }
}
.navbar-wrapper.active {
  display: none;
  .navbar-container {
    > a {
      > div {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 1160px) {
  .navbar-wrapper {
    display: none;
  }
}

